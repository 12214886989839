//
// Display
//

.display-1,
.display-2,
.display-3,
.display-4 {
  span {
    display: block;
    font-weight: $font-weight-light;
  }
}

.display-0 {
  font-size: 100px;
  font-weight: lighter;
}
