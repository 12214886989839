.rtl {
  &.g-sidenav-pinned .sidenav + .main-content {
    margin-right: 250px;
  }

  .sidenav + .main-content {
    margin-right: 62px;
  }

  .sidenav {
    // direction: initial;
    .navbar-nav {
      padding-right: 0;
    }
    .navbar-nav .nav-link > i {
      min-width: auto;
      margin-left: 1rem;
    }

    .navbar-nav .nav-link[data-toggle='collapse']:after {
      margin-right: auto;
      margin-left: initial;
      transform: rotate(180deg);
    }

    .navbar-nav .nav-link[data-toggle='collapse'][aria-expanded='true']:after {
      transform: rotate(90deg) !important;
    }

    .navbar-nav .nav .nav-link {
      padding-right: 15px;
    }
    .sidenav-toggler.active .sidenav-toggler-line:first-child {
      transform: translateX(0);
    }
    .sidenav-toggler.active .sidenav-toggler-line:last-child {
      transform: translateX(0);
    }
    .navbar-heading {
      text-align: right;
    }
    .scrollbar-inner {
      direction: initial;
      .navbar-nav {
        direction: rtl;
      }
    }
  }

  .sidenav .navbar-heading .docs-mini {
    padding-left: 0;
    padding-right: 3px;
  }

  .navbar-vertical.navbar-expand-xs
    .navbar-nav
    .nav
    .nav-link
    span.sidenav-normal {
    padding-right: 8px;
  }

  .dropdown-menu-left {
    text-align: right;
  }

  .card .card-body {
    text-align: right;
  }
}
